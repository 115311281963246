import Link from 'next/link'

export function Link2Video ({ children, slug }) {
  return (
    <Link href={'/content/' + slug} passHref>
      {children}
    </Link>
  )
}

export function Link2Show ({ children, slug }) {
  return (
    <Link href={'/sendungen/' + slug} passHref>
      {children}
    </Link>
  )
}
export function Link2Station ({ children, slug }) {
  return (
    <Link href={'/' + slug} passHref>
      {children}
    </Link>
  )
}

export function Link2Category ({ children, slug }) {
  return (
    <Link href={'/rubriken/' + slug} passHref>
      {children}
    </Link>
  )
}
